import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="booking-time-selection-facility-availability"
export default class extends Controller {
  static targets = ['date', 'time'];

  static values = {
    source: String,
    updatingExisting: false,
    enabled: true,
  };

  connect = () => {
    if (!this.enabledValue) {
      return;
    }
    this.setupEventListeners();
    this.triggerChangeEvent();
  };

  triggerChangeEvent = () => {
    this.dateTargets.forEach((dateTarget) => {
      if (dateTarget.value) {
        const event = new Event('change', { bubbles: true });
        dateTarget.dispatchEvent(event);
      }
    });
  };

  setupEventListeners = () => {
    this.dateTargets.forEach((dateTarget, index) => {
      dateTarget.addEventListener('change', (event) => {
        this.fetchAvailability(
          event.target.value,
          this.timeTargets[index],
          index === 0 ? 'startDate' : 'endDate'
        );
      });
    });
  };

  fetchAvailability = (dateValue, timeTarget, startOrEnd) => {
    const params = new URLSearchParams({
      date: dateValue,
      updating_existing: this.updatingExistingValue,
    });

    fetch(this.sourceValue + '?' + params, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) =>
        this.applyAvailability(res, timeTarget, dateValue, startOrEnd)
      )
      .catch((error) => {
        console.warn('error retrieving facility availability', error);
      });
  };

  applyAvailability = (
    availabilityData,
    timeTarget,
    selectedDate,
    startOrEnd
  ) => {
    for (const option of timeTarget.options) {
      const { status, reason } = this.isUnavailable(
        option.value,
        availabilityData,
        selectedDate,
        startOrEnd
      );
      if (status === 'available') {
        option.hidden = false;
        option.disabled = false;
        option.text = option.value;
      } else if (reason === 'closed') {
        option.hidden = true;
        option.text = reason;
      } else {
        option.disabled = true;
        option.text = reason;
      }
    }
  };

  isUnavailable = (optionTime, availabilityData, selectedDate, startOrEnd) => {
    const optionDate = new Date(`${selectedDate} ${optionTime}`);
    if (this.isClosed(optionDate, availabilityData['closed_hours'])) {
      return { status: 'unavailable', reason: 'closed' };
    } else if (
      this.isBooked(optionDate, availabilityData['booking_hours'], startOrEnd)
    ) {
      return { status: 'unavailable', reason: 'unavailable' };
    } else if (
      this.isBooked(
        optionDate,
        availabilityData['requested_booking_hours'],
        startOrEnd
      )
    ) {
      return { status: 'unavailable', reason: 'unavailable' };
    } else {
      return { status: 'available' };
    }
  };

  isClosed = (optionDate, closedHours) => {
    let closed = false;
    for (const closedHour of closedHours) {
      const closedStartDate = new Date(closedHour['start_time']);
      const closedEndDate = new Date(closedHour['end_time']);
      if (this.isClosedCheck(optionDate, closedStartDate, closedEndDate)) {
        closed = true;
        break;
      }
    }
    return closed;
  };

  isBooked = (optionDate, bookingHours, startOrEnd) => {
    let booked = false;
    for (const bookingHour of bookingHours) {
      const bookingStartDate = new Date(bookingHour['start_time']);
      const bookingEndDate = new Date(bookingHour['end_time']);
      if (
        this.isBookedCheck(
          optionDate,
          bookingStartDate,
          bookingEndDate,
          startOrEnd
        )
      ) {
        booked = true;
        break;
      }
    }
    return booked;
  };

  isClosedCheck = (date, startDate, endDate) => {
    return (
      date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime()
    );
  };

  isBookedCheck = (date, startDate, endDate, startOrEnd) => {
    if (startOrEnd === 'startDate') {
      return (
        date.getTime() >= startDate.getTime() &&
        date.getTime() < endDate.getTime()
      );
    } else {
      return (
        date.getTime() > startDate.getTime() &&
        date.getTime() <= endDate.getTime()
      );
    }
  };
}
